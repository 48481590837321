/** @format */

import React, {useEffect, useState} from 'react';
import {Box} from '@chakra-ui/layout';
import Patterns from '../patterns/patterns';
import {NextSeo} from 'next-seo';
import Layout from '../components/Layout';
import {getContentNode} from '../lib/api/contentNodes';
import {getSeoFields} from '../lib/getSeoFields';
import {useColorMode} from '@chakra-ui/react';
import {LetsTalkButton} from "../components/atoms/buttons/let's-talk-button/let's-talk-button";
import {getFooter, getMainMenu} from '../lib/api/option-pages';
interface HomePageProps {
	seo: any;
	post: {
		pageLayout: {
			flexibleContent: [Record<string, unknown>];
		};
	};
	fields: [Record<string, unknown>];
}
const HomePage = (props: HomePageProps) => {
	const {colorMode, toggleColorMode} = useColorMode();
	const [showCTAButton, setShowCTAButton] = useState(false);

	if (!props) return <div>Error</div>;
	function isInViewport(el) {
		if (el) {
			const rect = el.getBoundingClientRect();
			return rect.top < window.innerHeight && rect.bottom >= 0;
		}
	}
	if (typeof window !== 'undefined') {
		const cta = document.getElementById('CTABanner');

		document.addEventListener('scroll', function () {
			setShowCTAButton(isInViewport(cta));
		});
	}

	useEffect(() => {
		if (colorMode === 'dark') {
			toggleColorMode();
		}
	}, []);

	return (
		<Layout>
			<NextSeo {...props.seo} />
			<Box>
				{!showCTAButton && (
					<Box position={'fixed'} right={'32px'} top={'85%'} zIndex={101}>
						<LetsTalkButton />
					</Box>
				)}
				{props.fields?.map((post: any, index: number) => {
					return (
						<Patterns
							fieldGroupName={post.__typename}
							post={post}
							colorMode={colorMode}
							key={`${post.__typename}_${index + 1}`}
						/>
					);
				})}
			</Box>
		</Layout>
	);
};

export async function getStaticProps({preview, previewData}) {
	const id = preview ? previewData.id : `/homepage`;
	const idType = preview ? 'ID' : 'URI';
	const post = await getContentNode(id, idType, preview);
	let seo = !preview ? getSeoFields(post) : null;
	// if (seo && seo.canonical === `${process.env.NEXT_PUBLIC_APP_URL}/homepage`) {
	// 	seo = {
	// 		...seo,
	// 		canonical: process.env.NEXT_PUBLIC_APP_URL,
	// 		openGraph: {
	// 			...seo.openGraph,
	// 			url: '/',
	// 		},
	// 	};
	// }
	let fields = post.pageLayout.flexibleContent.map(field => field);
	const mainMenu = await getMainMenu();
	const footer = await getFooter();
	fields.unshift(mainMenu);
	fields.push(footer);

	return {props: {post, seo, fields}};
}
export default HomePage;
