import styles from "./lets-talk.module.scss";
import { Button, Flex, Text, Icon } from "@chakra-ui/react";
import { colors } from "../../../../styles/js/colors";
import Link from "next/link";
import { SmileyIcon } from "./smiley-icon";

export const LetsTalkButton = () => {
  return (
    <Link href={"/contact"} style={{
      zIndex: 101,
    }}>
      <a>
        <Button display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Flex justify="center">
            <Text className={styles.text} color={colors.navy}>
              Let&apos;s talk
            </Text>
            <Icon marginLeft={"8px"} width={"24px"} height={"24px"}>
              <SmileyIcon />
            </Icon>
          </Flex>
        </Button>
      </a>
    </Link>
  );
};
